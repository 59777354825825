import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import locale from '../localization';
import Layout from '../components/layout';

const HeadLine = styled.h1`
  color: red;
`;

function Architecture({ data }) {
  console.log(data);
  return (
    <Layout title={locale('architecture')}>
      <HeadLine>
        {locale('architecture')}
      </HeadLine>
      {data.artEntries.nodes.map((node) => {
        console.log(node, node.imageSrc, node.imageSrc.url);
        return (
          <img key={node.id} src={node.imageSrc.url} width="100%" />
        );
      })}
    </Layout>
  );
}

export default Architecture;

export const pageQuery = graphql`
  query  {
    artEntries: allContentfulArt {
      nodes {
        id
        imageSrc {
          url
        }
      }
    }
  }
`;
